import React, { useState } from 'react'
import  Axios  from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { domain } from '../env';
export const DoctorForm = () => {


    const[namedisis,setNamedisis]=useState(false)
    const[medicineyesno,setMedicine]=useState(false)

    const getDisisyes=()=>{
        setNamedisis(true)
    }
    const noDisisans=()=>{
        setNamedisis(false)
    }




    
    const MY=()=>{
        setMedicine(true)
    }
    const Mn=()=>{
        setMedicine(false)
    }
    const notify = () => toast.success("Successfully submitted!");

const [first_name,setFirstname]=useState('')
const [last_name,setLastname]=useState('')
const [phone,setPhone]=useState('')
const [age,setAge]=useState('')
const [problem_details,setProblem_details]=useState('')
const [duration,setDuration]=useState('')
const [consulted_doctor,setConsulted_doctor]=useState(false)
const [physical_problem,setPhysical_problem]=useState(false)
const [Name_of_disease,setName_of_disease]=useState('')
const [operation,setOperation]=useState(false)
const [medicine_regularly,setMedicine_regularly]=useState(false)
const [Name_of_medicine,setName_of_medicine]=useState('')


    


 
const handelSubmit=(e)=>{
  e.preventDefault();
  e.target.reset();

  Axios({
    method:'post',
    url:`${domain}/api/medicalform`,
    data:{
        'first_name':first_name,
        'last_name':last_name,
        'phone':phone,
        'age':age,
        'problem_details':problem_details,
        'duration':duration,

        'consulted_doctor':consulted_doctor,
        'physical_problem':physical_problem,
        'Name_of_disease':Name_of_disease,
        'operation':operation,
        'medicine_regularly':medicine_regularly,
        'Name_of_medicine':Name_of_medicine,
    }
}).then((response)=>{
     

   
    console.log(response.data,'successssssssssssssssssssssss')
    notify()
  
    
    
})







}










  return (
    
    <section class="h-100 bg-dark">
           <ToastContainer theme="colored" />
          
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col">
        <div class="card card-registration my-4">
          <div class="row g-0">
            <div class="col-xl-6 d-none d-xl-block">
              <img src="https://cdn.pixabay.com/photo/2017/12/02/23/28/doctor-2993956_960_720.jpg" 
                alt="Sample photo" class="img-fluid"
                style={{'borderTop':'.25rem','borderBottomLeftRadius':'.25rem'}}
           />
            </div>
            
            <div class="col-xl-6">




            <form onSubmit={handelSubmit}>
              <div class="card-body p-md-5 text-black">
                <h3 class="mb-5 text-uppercase">Meet the doctor form</h3>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <input required type="text" onChange={e=>setFirstname(e.target.value)} id="form3Example1m" class="form-control form-control-lg" />
                      <label class="form-label"  for="form3Example1m">First name*</label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <input required type="text" onChange={e=>setLastname(e.target.value)} id="form3Example1n" class="form-control form-control-lg" />
                      <label class="form-label" for="form3Example1n">Last name*</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <input type="number" onChange={e=>setPhone(e.target.value)} placeholder='Optional' id="form3Example1m1" class="form-control form-control-lg" />
                      <label class="form-label" for="form3Example1m1">Phone</label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <input type="number" onChange={e=>setAge(e.target.value)} required id="form3Example1n1" class="form-control form-control-lg" />
                      <label class="form-label" for="form3Example1n1">Age*</label>
                    </div>
                  </div>
                </div>

                <div class="form-outline mb-4">
                <textarea class="form-control" onChange={e=>setProblem_details(e.target.value)} id="textAreaExample1" rows="4"></textarea>

                  <label class="form-label"  for="form3Example8">Details of the problem*</label>
                </div>

                <div class="form-outline mb-4">
                <textarea onChange={e=>setDuration(e.target.value)} class="form-control" id="textAreaExample1" rows="4"></textarea>


                  <label class="form-label"  for="form3Example8">How long has the problem been going on ? </label>
                </div>

                <div class="d-md-flex justify-content-start align-items-center mb-4 py-2">

                  <h6 class="mb-0 me-4">Have you consulted any doctor before, for the problem.? </h6>

                  <div class="form-check form-check-inline mb-0 me-4">
                    <input required onChange={e=>setConsulted_doctor(e.target.value)}  class="form-check-input" type="radio" name="inlineRadioOptions" id="femaleGender"
                      value={true} />
                    <label class="form-check-label" for="femaleGender">Yes</label>
                  </div>

                  <div class="form-check form-check-inline mb-0 me-4">
                    <input onChange={e=>setConsulted_doctor(e.target.value)}   required class="form-check-input" type="radio" name="inlineRadioOptions" id="maleGender"
                      value={false} />
                    <label class="form-check-label" for="maleGender">No</label>
                  </div>



                </div>



<div class="d-md-flex justify-content-start align-items-center mb-4 py-2">

<h6 class="mb-0 me-4">Do you have any other physical problem.? </h6>

<div class="form-check form-check-inline mb-0 me-4">
  <input onClick={getDisisyes} required onChange={e=>setPhysical_problem(e.target.value)}    class="form-check-input" type="radio" name="inline" id="femaleGender"
    value={true} />
  <label class="form-check-label" for="femaleGender">Yes</label>
</div>






<div class="form-check form-check-inline mb-0 me-4">
  <input onClick={noDisisans}  onChange={e=>setPhysical_problem(e.target.value)}    required class="form-check-input" type="radio" name="inline" id="maleGender"
    value={false} />
  <label class="form-check-label" for="maleGender">No</label>
</div>
</div>



{namedisis && <div class="form-outline mb-4">
                  <input type="text"  onChange={e=>setName_of_disease(e.target.value)}   required id="form3Example8" class="form-control form-control-lg" />
                  <label class="form-label"  for="form3Example8">Name of the disease*</label>
                </div> }








                <div class="d-md-flex justify-content-start align-items-center mb-4 py-2">

<h6 class="mb-0 me-4">Have you ever had an operation ? </h6>

<div class="form-check form-check-inline mb-0 me-4">
  <input required onChange={e=>setOperation(e.target.value)}   class="form-check-input" type="radio" name="inline3" id="femaleGender"
    value={true} />
  <label class="form-check-label" for="femaleGender">Yes</label>
</div>






<div class="form-check form-check-inline mb-0 me-4">
  <input  required onChange={e=>setOperation(e.target.value)}  class="form-check-input" type="radio" name="inline3" id="maleGender"
    value={false} />
  <label class="form-check-label" for="maleGender">No</label>
</div>
</div>









               



               
                <div class="d-md-flex justify-content-start align-items-center mb-4 py-2">

<h6 class="mb-0 me-4">Do you take any medicine regularly.? </h6>

<div class="form-check form-check-inline mb-0 me-4">
  <input onClick={MY} required onChange={e=>setMedicine_regularly(e.target.value)}   class="form-check-input" type="radio" name="inline2" id="femaleGender"
    value={true} />
  <label class="form-check-label" for="femaleGender">Yes</label>
</div>






<div class="form-check form-check-inline mb-0 me-4">
  <input onClick={Mn} onChange={e=>setMedicine_regularly(e.target.value)}   required class="form-check-input" type="radio" name="inline2" id="maleGender"
    value={false}/>
  <label class="form-check-label" for="maleGender">No</label>
</div>
</div>
{medicineyesno && <div class="form-outline mb-4">
                  <input type="text" onChange={e=>setName_of_medicine(e.target.value)}  required id="form3Example8" class="form-control form-control-lg" />
                  <label class="form-label"  for="form3Example8">Name of the medicine*</label>
                </div>  }
            

             
                <div class="d-flex justify-content-end pt-3">
                {/* <input type="submit" class="btn btn-warning btn-lg ms-2" value="Submit" /> */}

                  <button type="reset" value="reset"  class="btn btn-light btn-lg">Reset all</button>
                  <button  type="submit" value="Submit" class="btn btn-warning btn-lg ms-2" >Submit form</button>
                </div>

              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}
