import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



import  Axios  from 'axios';  
import { useEffect, useState } from 'react'
import { domain } from '../env';
import ReactPaginate from 'react-paginate';
import 'react-multi-carousel/lib/styles.css';
export const MediaGalleryPage = () => {

    const [mediagallery,setMediagallery]=useState([])
	const mediagalleryget = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/mediagallery/`
        }).then(
            response => {
                setMediagallery(response.data)
                console.log(response.data,"media  data .....................")
              
            }
        )
    }
    useEffect(() => {
        mediagalleryget()
    }, [])


    const [currentItems, setCurrentItems] = useState([]);
const [pageCount, setPageCount] = useState(0);

const [itemOffset, setItemOffset] = useState(0);
const itemsPerPage = 6;
useEffect(() => {

  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  setCurrentItems(mediagallery.slice(itemOffset, endOffset));
  setPageCount(Math.ceil(mediagallery.length / itemsPerPage));
}, [itemOffset, itemsPerPage,mediagallery]);

const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % mediagallery.length;
  console.log(
    `User requested page number ${event.selected}, which is offset ${newOffset}`
  );
  setItemOffset(newOffset);
};


window.scrollTo(0,600 )
  return (
 <>
   <section class="page-header">
            <div class="page-header-bg" style={{'backgroundImage':'url(images/v.jpg)'}}>
            </div>
            <div class="container">
                <div class="page-header__inner">
                    <h2>Media Gallery</h2>
                    <ul class="thm-breadcrumb list-unstyled">
                        <li><a href="/">Home</a></li>
                        <li><span>/</span></li>
                        <li class="active">Gallery</li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="gallery-page">
            <div class="container">
                <div class="row">

                {currentItems && currentItems?.map((mg)=>(
                    <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="two-section__gallery-single">
                            <div class="two-section__gallery-img-inner">
                            <a href={`${domain}${mg?.image}`}>
	 <LazyLoadImage 
	 effect="blur" 
	 src={`${domain}${mg?.image}`}
	 style={{'height':'280px','border':'1px solid #e570e7','borderStyle':'dashed',}}
	
	     // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
/>
</a>
                            </div>
                            <div class="two-section__gallery-img-overly">
                                <div class="two-section__gallery-icon-bg">
                                </div>
                                <a class="img-popup" href={`${domain}${mg?.image}`}>
                                    <span class="icon-right-arrow"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </section>
        <div class="col-md-12 ">
         <div className='offset-md-5  '>
           
           
           <ReactPaginate
breakLabel="..."
nextLabel={<i className='fas fa-angle-double-right' />}
onPageChange={handlePageClick}
pageRangeDisplayed={6}
pageCount={pageCount}
previousLabel={<i className='fas fa-angle-double-left' />}
renderOnZeroPageCount={null}
containerClassName='pagination'
pageClassName='page-num'
previousLinkClassName="page-num"
nextLinkClassName="page-num"

activeLinkClassName="active"


/>
    </div>   
       </div>
 
 </>
  )
}
