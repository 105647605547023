import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';
import { useEffect, useState } from 'react'
import { domain } from '../env';
export const GrirlsTalkEn = () => {

    const [girlsTalk,setGirlstalk]=useState([])

    const getGirlstalk = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/girlstalk/`
        }).then(
            response => {
                setGirlstalk(response.data)
                console.log(response.data,"girlstalk  data .....................")
              
            }
        )
    }
    useEffect(() => {
        getGirlstalk()
    }, [])
   





  return (
    <div>

<section class="welcome-one">


    {girlsTalk && girlsTalk.map((gt)=>(
        <>
        
        <div class="container">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="welcome-one__left">
                            <div class="welcome-one__img-box wow slideInLeft" data-wow-delay="100ms"
                                data-wow-duration="2500ms">
                                <div class="welcome-one__img">
                                <LazyLoadImage 
            effect="blur" 
            src={`${domain}${gt?.image}`}
            style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
            key={gt?.id}
            alt="Loading....."
            />
                                </div>


                                <div class="welcome-one__small-img" >
                                    {/* <img src="images/gt.jpg" style={{'height':'170px','marginBottom':'-100px'}} /> */}
                                </div>


                                <div class="welcome-one-shape-1">
                                    <img src="images/shapes/welcome-one-shape-1.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="welcome-one__right">
                            <div class="section-title text-left">
                            
                                <h2 class="section-title__title">{gt?.top_section_title_english}</h2>
                            </div>
                            <p class="welcome-one__text-2" style={{'marginTop':'-30px'}}    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${gt?.details_english.slice(0,250)}...`)}} />
                            <div class="welcome-one__btn-box">
                                <a href="/about" class="thm-btn welcome-one__btn">Discover More</a>
                                <div class="welcome-one__video-link">
                                    <a href={gt?.video} class="video-popup">
                                        <div class="welcome-one__video-icon">
                                            <span class="fas fa-play"></span>
                                        </div>
                                    </a>
                                    <h4 class="welcome-one__video-text">Watch Video</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ))}
           












        </section>
    </div>
  )
}
