import React from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';  
import { useEffect, useState } from 'react'
import { domain } from '../env';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useParams } from 'react-router-dom';

export const BlogDetails = () => {
const [loading,setLoading]=useState(true)
  const {id}=useParams()
  // alert(id)
const [singleBlog,setSingleBlog]=useState(null)
  const getsingleblog = async () => {
    await Axios({
      method: "get",
      url:`${domain}/api/blog/${id}/`
    }).then(
        response => {
          setSingleBlog(response.data)
          
          
        }
    )
}
useEffect(() => {
  getsingleblog()
}, [id])
const [allblog,setAllBlog]=useState(null)

const getAllBlog = async () => {
    await Axios({
      method: "get",
      url:`${domain}/api/blog/`
    }).then(
        response => {
            setAllBlog(response.data)
            setLoading(false)
            // console.log(response.data,"wwwwwwwwwwwwwwwwwwwwwwww data .....................")
          
        }
    )
}
useEffect(() => {
    getAllBlog()
}, [])




if (singleBlog==null){
    return <h2 style={{'textAlign':'center'}}>Loading...</h2>
}




  return (
<>

<section class="page-header">
            <div class="page-header-bg"  style={{'backgroundImage':'url(images/v.jpg)'}}>
            </div>
            <div class="container">
                <div class="page-header__inner">
                    <h2>Blog Details</h2>
                    <ul class="thm-breadcrumb list-unstyled">
                        <li><a href="/">Home</a></li>
                        <li><span>/</span></li>
                        <li class="active">Blog Details</li>
                    </ul>
                </div>
            </div>
        </section>
    


        <section class="blog-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-7">
                        <div class="blog-details__left">
                            <div class="blog-details__img">
                                {/* <img src="images/blog/blog-details-img-1.jpg" /> */}
                                <LazyLoadImage 
                                effect="blur" 
                                src={`${domain}${singleBlog?.image}`}
                                // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
                               
                                />
                            </div>
                            <div class="blog-details__content">
                              
                                <h3 class="blog-details__title"> {singleBlog?.title_English}
                                </h3>
                                <p class="causes-one__text"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${singleBlog?.discription_English}`)}}   />

                            </div>
                       
                         
                           
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="sidebar">
                           
                            <div class="sidebar__single sidebar__post">
                                <h3 class="sidebar__title">Latest Posts</h3>
                                <ul class="sidebar__post-list list-unstyled">


                                    {allblog && allblog.slice(0,5).map((ps)=>(
                                        <li>
                                        <div class="sidebar__post-image">
                                        <LazyLoadImage 
                                effect="blur" 
                                src={`${domain}${ps?.image}`}
                                style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%','height':'90px'}}
                               
                                />
                                        </div>
                                        <div class="sidebar__post-content">
                                            <h3>
                                                <span class="sidebar__post-content-meta"><i
                                                        class="far fa-user-circle"></i> Youth planet</span>
                                                    <a href={`/blogdetails${ps?.id}`}>{ps?.title_English}</a>

                                            
                                            </h3>
                                        </div>
                                    </li>

                                    ))}
                               




                                  
                                </ul>
                            </div>
                            <div class="sidebar__single sidebar__category">
                                <h3 class="sidebar__title">Quick Links</h3>
                                <ul class="sidebar__category-list list-unstyled">
                                    <li><a href="/about">About <span class="icon-right-arrow"></span></a>
                                    </li>
                                    <li><a href="/allblog">Media & Blog <span class="icon-right-arrow"></span></a>
                                    </li>
                                    <li><a href="/achivement">Achivement <span
                                                class="icon-right-arrow"></span></a></li>
                                    <li><a href="/contact">Contact <span
                                                class="icon-right-arrow"></span></a></li>
                                 
                                </ul>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </section>
</>
  )
}
