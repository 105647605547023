import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';
import { useEffect, useState } from 'react'
import { domain } from '../env';
export const About = () => {

    const [girlsTalk,setGirlstalk]=useState(null)
    const [ourteam,setTeam]=useState(null)
    const getGirlstalk = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/girlstalk/`
        }).then(
            response => {
                setGirlstalk(response.data)
                console.log(response.data,"girlstalk  data .....................")
              
            }
        )
    }
    useEffect(() => {
        getGirlstalk()
    }, [])




    const Teamsget = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/team/`
        }).then(
            response => {
                setTeam(response.data)
                console.log(response.data,"girlstalk  data .....................")
              
            }
        )
    }
    useEffect(() => {
        Teamsget()
    }, [])


  return (
    <>
         <section class="page-header">
            <div class="page-header-bg"  style={{'backgroundImage':'url(images/v.jpg)'}}>
            </div>
            <div class="container">
                <div class="page-header__inner">
                    <h2>About</h2>
                    <ul class="thm-breadcrumb list-unstyled">
                        <li><a href="/">Home</a></li>
                        <li><span>/</span></li>
                        <li class="active">About</li>
                        
                    </ul>
                </div>
            </div>
        </section>
     {girlsTalk && girlsTalk.map((gt)=>(
        <section class="about-page">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="about-page__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                            <div class="about-page__img">
                            <LazyLoadImage 
            effect="blur" 
            src={`${domain}${gt?.image}`}
            style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
           
      
            />
                             
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="about-page__right">
                            <div class="section-title text-left">
                                <span class="section-title__tagline">Get to know us.</span>
                                <h2 class="section-title__title">{gt?.top_section_title_english}</h2>
                            </div>
                            <p class="about-page__text"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${gt?.details_english}`)}} />
                            <ul class="about-one__points list-unstyled">
                                <li>
                                    <div class="icon">
                                        <span class="icon-confirmation"></span>
                                    </div>
                                    <div class="text">
                                        <p>Join our Team</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="icon">
                                        <span class="icon-confirmation"></span>
                                    </div>
                                    <div class="text">
                                        <p>Quick Fundraising</p>
                                    </div>
                                </li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
     ))}
        





        <section class="volunteers-one">
            <div class="container">
                <div class="section-title text-center">
                    <span class="section-title__tagline">Ready to help you</span>
                    <h2 class="section-title__title">Our Team</h2>
                </div>
                <div class="row">
                    {ourteam && ourteam.map((tm)=>(
                    <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                                
                    <div class="volunteers-one__single">
                        <div class="volunteers-one__img">
                        <LazyLoadImage 
            effect="blur" 
            src={`${domain}${tm?.image}`}
      
           
      
            />
                        
                            <div class="volunteers-one__social">
                                <a href={`/${tm?.twitter_link}`}><i class="fab fa-twitter"></i></a>
                                <a href={`/${tm?.facebook_link}`}><i class="fab fa-facebook"></i></a>
                                <a href={`/${tm?.linkedin_link}`}><i class="fab fa-linkedin"></i></a>
                                <a href={`/${tm?.instagram_link}`}><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div class="volunteers-one__content">
                            <h4 class="volunteers-one__name">{tm?.name}</h4>
                            <p class="volunteers-one__title">{tm?.designation}</p>
                        </div>
                    </div>
                    </div>
                    ))}
                   
                    
                </div>
            </div>
        </section>
   

    
       
    </>
  )
}
