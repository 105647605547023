import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import 'react-multi-carousel/lib/styles.css';
import ReactPaginate from 'react-paginate';
import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';
import { useEffect, useState } from 'react'
import { domain } from '../env';
export const ScarsvPage = () => {
    const [scarsvall,setScarsvall]=useState([])
    const [loading,setLoading]=useState(true)

    const getScarsv = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/scarsv/`
        }).then(
            response => {
                setScarsvall(response.data)
                console.log(response.data,"scarsv  data .....................")
                setLoading(false)
            }
        )
    }
    useEffect(() => {
        getScarsv()
    }, [])



    // pagination



const [currentItems, setCurrentItems] = useState([]);
const [pageCount, setPageCount] = useState(0);

const [itemOffset, setItemOffset] = useState(0);
const itemsPerPage = 6;
useEffect(() => {

  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  setCurrentItems(scarsvall.slice(itemOffset, endOffset));
  setPageCount(Math.ceil(scarsvall.length / itemsPerPage));
}, [itemOffset, itemsPerPage,scarsvall]);

const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % scarsvall.length;
  console.log(
    `User requested page number ${event.selected}, which is offset ${newOffset}`
  );
  setItemOffset(newOffset);
};


window.scrollTo(0,600 )

if (loading===true){
    return <h2 style={{'textAlign':'center','height':'300px'}}>Loading...</h2>
}



  return (
    <>
     <section class="page-header">
            <div class="page-header-bg" style={{'backgroundImage':'url(images/v.jpg)'}}>
            </div>
            <div class="container">
                <div class="page-header__inner">
                    <h2>Activities</h2>
                    <ul class="thm-breadcrumb list-unstyled">
                        <li><a href ="/">Home</a></li>
                        <li><span>/</span></li>
                        <li class="active">Activities</li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="blog-page">
            <div class="container">
                <div class="row">

                {currentItems && currentItems.map((sc)=>(
                    <div class="col-xl-4 col-lg-4 col-md-6">
                     
                        <div class="blog-one__single">
                            <div class="blog-one__img">
                            <LazyLoadImage 
                                effect="blur" 
                                src={`${domain}${sc?.image}`}
                                // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
                                key={sc?.id}
                                />
                                <div class="blog-one__date">
                                    <p>{sc?.createdat}</p>
                                </div>
                                <a  href={`/scarsvdetails${sc?.id}`} >
                                    <span class="news-one__plus"></span>
                                </a>
                            </div>
                            <div class="blog-one__content">
                                <ul class="list-unstyled blog-one__meta">
                                    <li><a  href={`/scarsvdetails${sc?.id}`} ><i class="far fa-user-circle"></i> Ongoing Activities</a></li>
                                    <li><a  href={`/scarsvdetails${sc?.id}`} ><i class="far fa-comments"></i></a>
                                    </li>
                                </ul>
                                <h3 class="blog-one__title">
                                    <a href={`/scarsvdetails${sc?.id}`}>{sc?.title_english}</a>
                                </h3>
                                <p class="causes-one__text"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${sc?.details_English.slice(0,100)}...`)}}   />
                                <div class="blog-one__bottom">
                                    <a  href={`/scarsvdetails${sc?.id}`} class="blog-one__btn">Read More</a>
                                    <a href="blog-details.html" class="blog-one__arrow"><span
                                            class="icon-right-arrow"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </section>
        <div class="col-md-12 ">
         <div className='offset-md-5  '>
           
           
           <ReactPaginate
breakLabel="..."
nextLabel={<i className='fas fa-angle-double-right' />}
onPageChange={handlePageClick}
pageRangeDisplayed={6}
pageCount={pageCount}
previousLabel={<i className='fas fa-angle-double-left' />}
renderOnZeroPageCount={null}
containerClassName='pagination'
pageClassName='page-num'
previousLinkClassName="page-num"
nextLinkClassName="page-num"

activeLinkClassName="active"


/>
    </div>   
       </div>
    </>
  )
}

