import React from 'react'

export const LawyerDoctorEn = () => {
  return (
<>
<section class="two-boxes" >
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6">
                     
                        <div class="two-boxes__single wow slideInLeft" data-wow-delay="100ms"
                            data-wow-duration="2500ms">
                            <div class="two-boxes__single-bg"
                              style={{'backgroundImage':' url(https://thumbs.dreamstime.com/b/lawyer-judge-criminal-litigation-banner-law-justice-219301068.jpg)'}}>
                            </div>
                            <div class="two-boxes__icon">
                            <img src='images/lawyer.png' style={{'height':'70px'}} />
                            </div>
                            <div class="two-boxes__content">
                                <h3 class="two-boxes__title">Meet the lawyer</h3>
                                <p class="two-boxes__text">Lorem ipsum is simply free text available amet, consectetuer
                                    adipiscing elit.</p>
                                <a href="/lawyerform" class="donate-btn"> <i class="fa fa-gavel"></i>Fill a Form</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                     
                        <div class="two-boxes__single wow slideInRight" data-wow-delay="100ms"
                            data-wow-duration="2500ms">
                            <div class="two-boxes__single-bg"
                                style={{'backgroundImage':' url(https://img.freepik.com/free-photo/medical-stethoscope-white-surface_53876-95031.jpg?w=2000)'}}>
                            </div>
                            <div class="two-boxes__icon two-boxes__icon-clr-scondary">
                                <span class="icon-health-check"></span>
                            </div>
                            <div class="two-boxes__content">
                                <h3 class="two-boxes__title">Meet the doctor</h3>
                                <p class="two-boxes__text">Lorem ipsum is simply free text available amet, consectetuer
                                    adipiscing elit.</p>
                                <a href="/doctorform" class="donate-btn"> <i class="fa fa-stethoscope"></i> Fill a Form</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

</>
  )
}
