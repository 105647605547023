import React from 'react'

export const SupportDonate = () => {
  return (
 <>
  <section class="feature-one" style={{'marginBottom':'20px','marginTop':'-50px'}}>
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                      
                        <div class="feature-one__single">
                            <div class="feature-one__icon">
                                <span class="icon-sponsor"></span>
                            </div>
                            <div class="feature-one__content">
                                <h4 class="feature-one__title">Support us</h4>
                                <p class="feature-one__text">There are many variations of available but the majority
                                    have suffered alteration.</p>
                            </div>
                            <a href="/support" class="feature-one__btn">More</a>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms" style={{'borderLeft':'1px solid #fff'}}  >
                      
                        <div class="feature-one__single feature-one__single-2">
                            <div class="feature-one__icon">
                                <span class="icon-solidarity"></span>
                            </div>
                            <div class="feature-one__content">
                                <h4 class="feature-one__title">Donate us</h4>
                                <p class="feature-one__text">There are many variations of available but the majority
                                    have suffered alteration.</p>
                            </div>
                            <a href="/donate" class="feature-one__btn">More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 </>
  )
}
