import React, { useEffect,useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'


import  Axios  from 'axios';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { domain } from '../env';

export const HeadEn = () => {

    
 
    
 
    const [setting,setStting]=useState([])  

  
  
    const getSetting = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/setting/`
        }).then(
            response => {
                setStting(response.data)
                
              
            }
        )
    }
    useEffect(() => {
        getSetting()
    }, [])
  
    const [scarsvall,setScarsvall]=useState([])

    const getScarsv = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/scarsv/`
        }).then(
            response => {
                setScarsvall(response.data)
                console.log(response.data,"scarsv  data .....................")
              
            }
        )
    }
    useEffect(() => {
        getScarsv()
    }, [])





    
    const [input,setInput]=useState('') //for search
    const [output,setOutput]=useState([]) //for search

    useEffect(()=>{
        setOutput([])
        scarsvall.filter((val=>{
           if(val.title_english.toLowerCase().includes(input.toLowerCase())){
              setOutput(output=>[...output,val])
           }
       }))
       },[input])

       

  return (
    <>
        <div className="application">
         
       
        </div>

        
      <header class="main-header clearfix">

      {setting && setting.map((sa)=>(
        
            <div class="main-header__top xxx">
                <div class="main-header__top-left">
                    <p class="main-header__top-text">Welcome to Youth Planet.</p>
                    <div class="main-header__top-social">
                        <a href={`${sa?.twitter_link}`}><i class="fab fa-twitter"></i></a>
                       <a href={`${sa?.facebook_link}`}><i class="fab fa-facebook"></i></a>
                        <a href={`${sa?.linkedin_link}`}><i class="fab fa-linkedin"></i></a>
                        <a href={`${sa?.instagram_link}`}><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
                <div class="main-header__top-right">
                    <ul class="list-unstyled main-header__top-address">
                        <li>
                            <div class="icon">
                                <span class="icon-pin"></span>
                            </div>
                            <div class="text">
                                <p>{sa?.addressEn}</p>
                            </div>
                        </li>
                        <li>
                            <div class="icon">
                                <span class="icon-email"></span>
                            </div> 
                            <div class="text">
                                <p><a href="#">{sa?.email}</a></p>
                               
                            </div>
                            <div class="text">
                                <p><a href='https://bn.youthplanet.org.bd/' style={{'backgroundColor':'#A020F0','color':'#fff','padding':'2px 15px','borderRadius':'10px 100px / 120px','marginLeft':'20px'}}>বাংলা</a></p>
                               
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

      ))}

            <nav class="main-menu clearfix">
                <div class="main-menu-wrapper clearfix">

{setting && setting.map((sa)=>(
  <div class="main-menu-wrapper__left">
  <div class="main-menu-wrapper__logo" style={{'height':'0px','marginLeft':'-40px','marginTop':'-10px','paddingLeft':'10px'}}>
    <a  href='/'>
  <LazyLoadImage 
            effect="blur" 
            src={`${domain}${sa?.logo}`}
            style={{'height':'70px',}}
           
      
            />
            </a>
  </div>
  <div class="main-menu-wrapper__call">
      <div class="main-menu-wrapper__call-icon">
          <span class="icon-call"></span>
      </div>
      <div class="main-menu-wrapper__call-number">
          <p>Call Anytime</p>
          <h5><a>+ 88 {sa?.phoneEn}</a></h5>
      </div>
  </div>
</div>

))}

                  



                    <div class="main-menu-wrapper__main-menu">
                        <a href="#" class="mobile-nav__toggler"><i class="fa fa-bars"></i></a>
                        <ul class="main-menu__list">
                            <li class="current">
                                <a href="/">HOME</a>
                              
                            </li>
                            <li >
                                <a href="/about">ABOUT</a>
                              
                            </li>
                            <li >
                                <a href="/scarsv">ACTIVITIES</a>
                            </li>
                            <li >
                                <a href="/achivement" >ACHIEVEMENT</a>
                               
                            </li>
                            <li >
                                <a href="/mediagallery">MEDIA GALLERY</a>
                           
                            </li>
                            
                            <li><a href="/allblog">BLOG</a></li>

                            <li><a href="/contact">CONTACT</a></li>
                           
                        </ul>
                    </div>
                    <div class="main-menu-wrapper__right">
                        
                        <div class="main-menu-wrapper__search-cat">
                            <a href="#" class="main-menu-wrapper__search search-toggler icon-magnifying-glass"></a>

                        </div>
                        
                     
                    </div>
                </div>
            </nav>
        </header>

        <div class="mobile-nav__wrapper">
        <div class="mobile-nav__overlay mobile-nav__toggler"></div>
   
        <div class="mobile-nav__content">
            <span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

            <div class="logo-box">
                {/* <a href="/" aria-label="logo image"><img src="assets/images/resources/logo-2.png" alt="" /></a> */}
            </div>
        
            <div class="mobile-nav__container"></div>
            
            {setting && setting.map((sa)=>(
                <>
            <ul class="mobile-nav__contact list-unstyled">
                <li>
                    <i class="fa fa-envelope"></i>
                    <a href="#">{sa?.email}</a>
                </li>
                <li>
                    <i class="fa fa-phone-alt"></i>
                    <a href='#'>{sa?.phoneEn}</a>
                </li>
                <li>
                <i class="fa fa-language"></i>
               <a href='https://bn.youthplanet.org.bd/' style={{'backgroundColor':'#A020F0','color':'#fff','padding':'2px 15px','borderRadius':'10px 100px / 120px','marginLeft':'20px'}}>বাংলা</a>
            </li>
            </ul>
            <div class="mobile-nav__top">
                <div class="mobile-nav__social">
                        <a href={`${sa?.twitter_link}`}><i class="fab fa-twitter"></i></a>
                        <a href={`${sa?.facebook_link}`}><i class="fab fa-facebook"></i></a>
                        <a href={`${sa?.linkedin_link}`}><i class="fab fa-linkedin"></i></a>
                        <a href={`${sa?.instagram_link}`}><i class="fab fa-instagram"></i></a>
                </div>
            </div>
            </>
            ))}


        </div>
        
    </div>
    

    <div class="search-popup">
        <div class="search-popup__overlay search-toggler"></div>
       
        <div class="search-popup__content">
            <form action="#" onSubmit={(e)=>(e.preventDefault())}>
                <label for="search" class="sr-only">search here</label>
                <input type="text"  onChange={e=>setInput(e.target.value)} id="search" placeholder="Search Here..." />
                <button type="submit" aria-label="search submit" class="thm-btn" >
                    <i class="icon-magnifying-glass"></i>
                </button>
               {input.length > 0 && output && output.map((all)=>(
                <>
                <a href={`/scarsvdetails${all?.id}`} style={{'padding':'20px','textAlign':'center'}}>{all?.title_english}</a>

                </>
               ))}


{output.length === 0 && input.length > 0 &&
                <>
              <p style={{'padding':'20px','color':'red'}}>Sorry Ongoing Activities not found !!!</p>

                </>
}
            </form>
           
       
        </div>
     
    </div>
    </>
  )
}
