
import React, { useState } from 'react'
import  Axios  from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { domain } from '../env';
export const VolunteerForm = () => {
  

const[nameEnglish,setNameEnglish]=useState('')
const[nameBangla,setNameBangla]=useState('')
const[phone,setPhone]=useState('')

const[age,setAge]=useState('')
const[email,setEmail]=useState('')
const[address,setAddress]=useState('')
const[facebooklink,setFacebooklink]=useState('')

const[knowYouthPlanet,setKnowYouthPlanet]=useState(false)

const[whyWantWork,setWhyWantWork]=useState('')

const[beforeWorkAsVolunteer,setBeforeWorkAsVolunteer]=useState(false)


const[expectation,setExpectation]=useState('')

const[agree,setAgree]=useState(false)

const notify = () => toast.success("Successfully submitted!");

const handelSubmitForm=(e)=>{
  e.preventDefault();
  e.target.reset();
   
  Axios({
    method:'post',
    url:`${domain}/api/volunteer`,
    data:{
        'nameEnglish':nameEnglish,
        'nameBangla':nameBangla,
        'phone':phone,
        'age':age,
        'email':email,

        'address':address,
        'facebooklink':facebooklink,

        'knowYouthPlanet':knowYouthPlanet,
        'whyWantWork':whyWantWork,

        'beforeWorkAsVolunteer':beforeWorkAsVolunteer,
        'expectation':expectation,
        'agree':agree,
      
    }
}).then((response)=>{
     

   
    console.log(response.data,'successssssssssssssssssssssss')
    notify()
  
    
    
})

}

  return (
    <section class="h-100 bg-dark">
   <ToastContainer theme="colored" />
   <div class="container py-5 h-100">
     <div class="row d-flex justify-content-center align-items-center h-100">
       <div class="col">
         <div class="card card-registration my-4">
           <div class="row g-0">
          
             
             <div class="col-xl-12">
             <form onSubmit={handelSubmitForm}>
               <div class="card-body p-md-5 text-black">
                 <h3 class="mb-5 text-uppercase">Volunteer form</h3>
 
                 <div class="row">
                   <div class="col-md-6 mb-4">
                     <div class="form-outline">
                       <input required type="text" onChange={e=>setNameEnglish(e.target.value)}  id="form3Example1m" class="form-control form-control-lg" />
                       <label class="form-label" for="form3Example1m">Name English*</label>
                     </div>
                   </div>
                   <div class="col-md-6 mb-4">
                     <div class="form-outline">
                       <input required type="text" onChange={e=>setNameBangla(e.target.value)} id="form3Example1n" class="form-control form-control-lg" />
                       <label class="form-label" for="form3Example1n">Name Bangla*</label>
                     </div>
                   </div>
                 </div>
 
                 <div class="row">
                   <div class="col-md-6 mb-4">
                     <div class="form-outline">
                       <input type="number" onChange={e=>setPhone(e.target.value)} required id="form3Example1m1" class="form-control form-control-lg" />
                       <label class="form-label" for="form3Example1m1">Phone*</label>
                     </div>
                   </div>
                   <div class="col-md-6 mb-4">
                     <div class="form-outline">
                       <input type="number" onChange={e=>setAge(e.target.value)} required id="form3Example1n1" class="form-control form-control-lg" />
                       <label class="form-label" for="form3Example1n1">Age*</label>
                     </div>
                   </div>
                 </div>
                 <div class="form-outline mb-4">
                   <input type="email" onChange={e=>setEmail(e.target.value)}   required id="form3Example8" class="form-control form-control-lg" />
                   <label class="form-label"  for="form3Example8">Email*</label>
                 </div>
 
 
                 <div class="form-outline mb-4">
                   <input type="text" onChange={e=>setAddress(e.target.value)}   required id="form3Example8" class="form-control form-control-lg" />
                   <label class="form-label"  for="form3Example8">Address*</label>
                 </div>
 
 
 
                 <div class="form-outline mb-4">
                   <input type="text" onChange={e=>setFacebooklink(e.target.value)}   required id="form3Example8" class="form-control form-control-lg" />
                   <label class="form-label"  for="form3Example8">Facebook Profile Link*</label>
                 </div>

                 <div class="d-md-flex justify-content-start align-items-center mb-4 py-2">
 
                <h6 class="mb-0 me-4">Do you know about Youth Planet?* : </h6>

                <div class="form-check form-check-inline mb-0 me-4">
                <input required onChange={e=>setKnowYouthPlanet(e.target.value)}  class="form-check-input" type="radio" name="inlineRadioOptions" id="femaleGender"
                    value={true} />
                <label class="form-check-label" for="femaleGender">Yes</label>
                </div>

                <div class="form-check form-check-inline mb-0 me-4">
                <input  required class="form-check-input" type="radio" name="inlineRadioOptions" id="maleGender"
                    value={false}/>
                <label class="form-check-label" for="maleGender">No</label>
                </div>



                </div>
 
                 <div class="form-outline mb-4">
                 <textarea onChange={e=>setWhyWantWork(e.target.value)} class="form-control" required  id="textAreaExample1" rows="4"></textarea>
 
 
                   <label class="form-label" for="form3Example8">Why do you want to work? </label>
                 </div>
 
                 <div class="d-md-flex justify-content-start align-items-center mb-4 py-2">
 
                <h6 class="mb-0 me-4">Have you ever worked as a volunteer?* : </h6>

                <div class="form-check form-check-inline mb-0 me-4">
                <input required onChange={e=>setBeforeWorkAsVolunteer(e.target.value)}  class="form-check-input" type="radio" name="inlineRadioOptions2" id="femaleGender"
                    value={true} />
                <label class="form-check-label" for="femaleGender">Yes</label>
                </div>

                <div class="form-check form-check-inline mb-0 me-4">
                <input  required class="form-check-input" type="radio" name="inlineRadioOptions2" id="maleGender"
                    value={false}/>
                <label class="form-check-label" for="maleGender">No</label>
                </div>



                </div>

                
                <div class="form-outline mb-4">
                 <textarea onChange={e=>setExpectation(e.target.value)} required class="form-control"  id="textAreaExample1" rows="4"></textarea>
 
 
                   <label class="form-label" for="form3Example8">What are your expectations for the organization as a volunteer?*</label>
                 </div>





                 <b><i class="mb-0 me-4">* "I will abide by all the principles and ideals of Youth Planet. I will not do anything against the constitution of the organization, I will not do anything against the law of Bangladesh. I will always sacrifice myself for people and country. "*</i></b>


 <div class="d-md-flex justify-content-start align-items-center mb-4 py-2">
 
 <h6 class="mb-0 me-4">Do you agree with the above statement?* : </h6>

 <div class="form-check form-check-inline mb-0 me-4">
 <input required onChange={e=>setAgree(e.target.value)}  class="form-check-input" type="radio" name="inlineRadioOptions3" id="femaleGender"
     value={true} />
 <label class="form-check-label" for="femaleGender">Yes</label>
 </div>

 <div class="form-check form-check-inline mb-0 me-4">
 <input  required class="form-check-input" type="radio" name="inlineRadioOptions3" id="maleGender"
     value={false}/>
 <label class="form-check-label" for="maleGender">No</label>
 </div>



 </div>
             
 

                
 
             
 
              
 
                 <div class="d-flex justify-content-end pt-3">
                 {/* <input type="submit" class="btn btn-warning btn-lg ms-2" value="Submit" /> */}
 
                   <button type="reset" value="reset"  class="btn btn-light btn-lg">Reset all</button>
                   <button type="submit" value="Submit" class="btn btn-warning btn-lg ms-2">Submit form</button>
                 </div>
 
               </div>
               </form>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
  )
}
