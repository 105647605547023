import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { domain } from '../env';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
export const WhoWeAre = () => {
    
    const [whoweare,setWhoweare]=useState([])

    const getWhoweare = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/whowe/`
        }).then(
            response => {
                setWhoweare(response.data)
                console.log(response.data,"wwwwwwwwwwwwwwwwwwwwwwww data .....................")
              
            }
        )
    }
    useEffect(() => {
        getWhoweare()
    }, [])
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
  return (
    <>

    <style>
      {
        `
//         .we-believe__title {
//           width: 200px;
//       }
//  .we-believe__text{
//   width: 200px;
//  }
//  .blog-one__bottom{
//   width: 200px;
//  }


// .we-believe__text{
// width: 250px;
// }
// .blog-one__bottom{
// width: 250px;
// }
// }



// .we-believe__text{
// width: 400px;
// }
// .blog-one__bottom{
// width: 400px;
// }
// }

// .we-believe__text{
// width: 300px;
// }
// .blog-one__bottom{
// width: 300px;
// }

.we-believe {
  position: relative;
  display: block;
  padding:0px;
  z-index: 1;
}

        `
      }
    </style>
       <section class="we-believe">
            <div class="we-believe-map" style={{'backgroundImage':"url(images/shapes/we-believe-map.png)"}}></div>
            <div class="container">
                <div class="section-title text-center">
                
                    <h2 class="section-title__title">What we do ? </h2>
                </div>
                <div class="row who" >
                   
                        
                    <Carousel responsive={responsive} autoPlay={true}  keyBoardControl={true}  ssr={true}  swipeable={true} infinite={true} >
                {whoweare && whoweare.map((who)=>(
                        <li class=" fadenUp" data-wow-delay="100ms" style={{'textAlign':'center',"width":'90%'}}>
                        <div class="girlstalk">
                            <span >
        <LazyLoadImage 
            effect="blur" 
            src={`${domain}${who?.image}`}
            style={{'height':'150px','borderRadius':'25% 10%','border':'1px solid #a020f0','borderStyle':'dashed'}}
            key={who?.id}
            />
                                {/* <img src='images/g.jpg' style={{'height':'150px','borderRadius':'25% 10%','border':'1px solid #a020f0','borderStyle':'dashed'}} /> */}

                            </span>
                        </div>
                        <div className='newpart' style={{}}>
                        <h3 class="we-believe__title" ><a href={`/whodetails${who?.id}`}>{who?.option_title_english} </a></h3>
                        <p class="we-believe__text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${who?.short_details_english.slice(0,60)}...`)}} /> 
                       
                        <div class="blog-one__bottom" >

                            <a  href={`/whodetails${who?.id}`} class="blog-one__btn" style={{'color':'#A020F0','width':'200px'}}>Read More</a>
                            <a href={`/whodetails${who?.id}`} class="blog-one__arrow"><span
                                    class="icon-right-arrow"></span></a>
                        </div>

                        </div>
                    </li>

                      ))}
</Carousel>
                    </div>
                </div>
         
        </section>




        
    </>
  )
}
