import React, { useState } from 'react'
import  Axios  from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { domain } from '../env';
export const LawyerForm = () => {  
    const[ifyes,setIfyes]=useState(false)
    const getyes=()=>{
        setIfyes(true) 
    }
    const noans=()=>{
        setIfyes(false)
    }
    






const [first_name,setFirstname]=useState('')
const [last_name,setLastname]=useState('')
const [phone,setPhone]=useState('')
const [age,setAge]=useState('')
const [occupation,setOccupation]=useState('')

const [complaint_against,setComplaint_against]=useState('')
const [complaint_details,setComplaint_details]=useState('')

const [taken_police_help,setTaken_police_help]=useState(false)
const [help_type,setHelp_type]=useState('')

const notify = () => toast.success("Successfully submitted!");

const handelSubmitForm=(e)=>{
  e.preventDefault();
  e.target.reset();
   
  Axios({
    method:'post',
    url:`${domain}/api/lawyerform`,
    data:{
        'first_name':first_name,
        'last_name':last_name,
        'phone':phone,
        'age':age,
        'occupation':occupation,

        'complaint_against':complaint_against,
        'complaint_details':complaint_details,

        'taken_police_help':taken_police_help,
        'help_type':help_type,
      
    }
}).then((response)=>{
     

   
    console.log(response.data,'successssssssssssssssssssssss')
    notify()
  
    
    
})







}
















  return (
   <>
   <section class="h-100 bg-dark">
   <ToastContainer theme="colored" />
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col">
        <div class="card card-registration my-4">
          <div class="row g-0">
            <div class="col-xl-6 d-none d-xl-block">
              <img src="https://tuccilaw.com/wp-content/uploads/2019/04/Lawyer-Long-Branch.jpg" 
                alt="Sample photo" class="img-fluid"
                style={{'borderTop':'.25rem','borderBottomLeftRadius':'.25rem'}}
           />
            </div>
            
            <div class="col-xl-6">
            <form onSubmit={handelSubmitForm}>
              <div class="card-body p-md-5 text-black">
                <h3 class="mb-5 text-uppercase">Meet the lawyer form</h3>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <input required type="text" onChange={e=>setFirstname(e.target.value)} id="form3Example1m" class="form-control form-control-lg" />
                      <label class="form-label" for="form3Example1m">First name*</label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <input required type="text" onChange={e=>setLastname(e.target.value)} id="form3Example1n" class="form-control form-control-lg" />
                      <label class="form-label" for="form3Example1n">Last name*</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <input type="number" onChange={e=>setPhone(e.target.value)} placeholder='optional ' id="form3Example1m1" class="form-control form-control-lg" />
                      <label class="form-label" for="form3Example1m1">Phone</label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <input type="number" onChange={e=>setAge(e.target.value)} required id="form3Example1n1" class="form-control form-control-lg" />
                      <label class="form-label" for="form3Example1n1">Age*</label>
                    </div>
                  </div>
                </div>



                <div class="form-outline mb-4">
                  <input type="text"  onChange={e=>setOccupation(e.target.value)} required id="form3Example8" class="form-control form-control-lg" />
                  <label class="form-label"  for="form3Example8">Occupation*</label>
                </div>



                <div class="form-outline mb-4">
                  <input type="text"  onChange={e=>setComplaint_against(e.target.value)} required id="form3Example8" class="form-control form-control-lg" />
                  <label class="form-label"  for="form3Example8">Complaint  against whom?*</label>
                </div>

                <div class="form-outline mb-4">
                <textarea class="form-control" onChange={e=>setComplaint_details(e.target.value)} id="textAreaExample1" rows="4"></textarea>


                  <label class="form-label" for="form3Example8">Complaint  details* </label>
                </div>

                <div class="d-md-flex justify-content-start align-items-center mb-4 py-2">

                  <h6 class="mb-0 me-4">Have you ever taken  the help of police in this regard?* : </h6>

                  <div class="form-check form-check-inline mb-0 me-4">
                    <input required onClick={getyes} onChange={e=>setTaken_police_help(e.target.value)} class="form-check-input" type="radio" name="inlineRadioOptions" id="femaleGender"
                      value={true} />
                    <label class="form-check-label" for="femaleGender">Yes</label>
                  </div>

                  <div class="form-check form-check-inline mb-0 me-4">
                    <input onClick={noans} required onChange={e=>setTaken_police_help(e.target.value)} class="form-check-input" type="radio" name="inlineRadioOptions" id="maleGender"
                      value={false}/>
                    <label class="form-check-label" for="maleGender">No</label>
                  </div>



                </div>

{ifyes &&  <div class="form-outline mb-4">
                <textarea class="form-control" onChange={e=>setHelp_type(e.target.value)} id="textAreaExample1" rows="4"></textarea>
                  <label class="form-label" for="form3Example9">What kind of help did you get?</label>
                </div>}
               

            

             

                <div class="d-flex justify-content-end pt-3">
                {/* <input type="submit" class="btn btn-warning btn-lg ms-2" value="Submit" /> */}

                  <button type="reset" value="reset"  class="btn btn-light btn-lg">Reset all</button>
                  <button type="submit" value="Submit" class="btn btn-warning btn-lg ms-2">Submit form</button>
                </div>

              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
   </>
  )
}
