import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { BlogMedia } from './BlogMedia'
import { GrirlsTalkEn } from './GrirlsTalkEn'
import { LawyerDoctorEn } from './LawyerDoctorEn'
import { MediaGalleryEn } from './MediaGalleryEn'
import { MixititemEn } from './MixititemEn'
import { ScarsvEn } from './ScarsvEn'
import { SliderEn } from './SliderEn'
import { SupportDonate } from './SupportDonate'
import { VolunteerEn } from './VolunteerEn'
import { WhatTheySayEn } from './WhatTheySayEn'
import { WhoWeAre } from './WhoWeAre'
export const HomeEn = () => {
  
  return (
    
    <>
     <Helmet>
                <meta charSet="utf-8" />
             
                <script src='https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.3.7/js/swiper.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery-appear/0.1/jquery.appear.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js'></script>

            </Helmet>
    <div class="page-wrapper">
    
    <SliderEn />
    <GrirlsTalkEn/>
    <WhoWeAre />
  
    <ScarsvEn />
    <LawyerDoctorEn />
    <MediaGalleryEn />
    <WhatTheySayEn />
    <BlogMedia />
    <VolunteerEn />
    {/* <MixititemEn /> */}
  
    <SupportDonate />
    
    

    </div>
    </>
  )
}
